<template>
  <div class="all-height">
    <div class="pt-5">
      <div class="title mb-6">
        <v-icon>mdi-chevron-triple-right</v-icon>
        <span class="ml-4">Assignment Summary</span>
      </div>
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="" @click="$nova.gotoLink({hash: '#ongoing'})">
            <v-card-text class="border-left-warning d-flex align-center hoverable cursor-pointer">
              <div class="warning--text px-2 display-2 font-weight-thin">{{assignment_ongoing.length}}</div>
              <div class="title ml-2">Ongoing</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="" @click="$nova.gotoLink({hash: '#completed'})">
            <v-card-text class="border-left-success d-flex align-center hoverable cursor-pointer">
              <div class="success--text px-2 display-2 font-weight-thin">{{assignment_completed.length}}</div>
              <div class="title ml-2">Completed</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="" @click="$nova.gotoLink({hash: '#yettostart'})">
            <v-card-text class="border-left-info d-flex align-center hoverable cursor-pointer">
              <div class="info--text px-2 display-2 font-weight-thin">{{assignment_yettostart.length}}</div>
              <div class="title ml-2">Yet to start</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="" @click="$nova.gotoLink({hash: '#cancelled'})">
            <v-card-text class="border-left-error d-flex align-center hoverable cursor-pointer">
              <div class="error--text px-2 display-2 font-weight-thin">{{assignment_cancelled.length}}</div>
              <div class="title ml-2">Cancelled</div>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <div class="mt-6">&nbsp;</div>

      <div class="title mb-6">
        <v-icon>mdi-chevron-triple-right</v-icon>
        <span class="ml-4">Ongoing Assignments</span>
      </div>
      <v-card>
        <v-card-text class="title text-center py-4" v-if="assignment_ongoing.length === 0">
          <span class="grey--text">No Ongoing Assignments</span>
        </v-card-text>
        <v-card-text v-else class="mt-4">
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-center subtitle-1 font-weight-bold">Areas Covered</div>
              <div>
                <canvas id="ongoingarea" style="max-height:300px"></canvas>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-center subtitle-1 font-weight-bold">Observation Summary Rating Wise</div>
              <div>
                <canvas id="ongoingrating" style="max-height:300px"></canvas>
              </div>
            </v-col>
          </v-row>
          <v-list dense class="px-0 mt-4">
            <v-divider></v-divider>
            <template v-for="(v,k) in assignment_ongoing">
              <div :key="k">
                <v-list-item link dense @click="$nova.gotoLink({path: '/ia/assignment/view/'+v._id})" class="">
                  <v-list-item-content class="mb-2">
                    <v-list-item-title class="d-flex align-center my-0">
                      
                      <span class="title font-weight-bold mr-4" label>{{k+1}}</span>
                      <strong class="mr-4">{{(v.location_detail || {}).name}}</strong>
                      {{$nova.formatDate(v.from)}} - {{$nova.formatDate(v.to)}}
                      <v-spacer></v-spacer>
                      <v-btn icon @click.prevent="addassignmentdata = v;assignmentdialog={}"><v-icon>mdi-pencil</v-icon></v-btn>
                      <v-btn icon @click.prevent="addassignmentdata = v;assignmentdialog={}"><v-icon>mdi-stop</v-icon></v-btn>
                    </v-list-item-title>
                    <div class="pl-5">
                     
                      <v-chip class="ml-2" :color="`${colors[sv]}`" label v-for="(sv, sk) in v.scope" :key="sk" small>{{arealist[sv]}}</v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </v-card-text>
      </v-card>

      <div class="my-6">&nbsp;</div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'
import {Chart, PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController} from 'chart.js';
Chart.register(PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController);


export default {
  name: 'iadashboard',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      arealist: {},
      colors: {},
      arealist_ongoing: [],
      arealist_completed: [],
      arealist_yettostart: [],
      arealist_cancelled: [],
      assignment_ongoing: [],
      assignment_completed: [],
      assignment_yettostart: [],
      assignment_cancelled: [],
      charts: {},
      chart_ongoing_rating_data: {
        type: 'polarArea',
        data: {
          labels: ["High", "Medium", "Low"],
          datasets: [
            {
              label: 'Rating',
              data: [34, 47, 23],
              backgroundColor: [
                this.$nova.hexToRgbA(this.$vuetify.theme.themes.dark.error,0.5),
                this.$nova.hexToRgbA(this.$vuetify.theme.themes.dark.warning,0.5),
                this.$nova.hexToRgbA(this.$vuetify.theme.themes.dark.info,0.5),
              ]
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          }
        }
      },
      area_chart_data: {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Areas',
              data: [],
              backgroundColor: []
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            // legend: {
            //   position: 'top',
            // },
          }
        }
      }
    }
  },
  // components: {
  //   // pageHeader,managelist
  // },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/ia/assignment/dashboard").then(dt => {
        // console.log(dt.data.data[0].assignments);
        if(dt.data.status === "success" && dt.data.data.length > 0) {
          this.arealist = dt.data.data[0].arealist || {};
          let clrs = this.$nova.getColors(Object.keys(this.arealist).length, 0.5);
          for (let i = 0; i < Object.keys(this.arealist).length; i++) {
            const el = Object.keys(this.arealist)[i];
            this.colors[el] = clrs[i];
          }
          this.arealist = dt.data.data[0].arealist || {};
          let areas = dt.data.data[0].areas || {};
          this.arealist_ongoing = areas.ongoing || {};
          this.arealist_completed = areas.completed || {};
          this.arealist_yettostart = areas.yettostart || {};
          this.arealist_cancelled = areas.cancelled || {};
          let assignments = dt.data.data[0].assignments || {};
          this.assignment_ongoing = assignments.ongoing || [];
          this.assignment_completed = assignments.completed || [];
          this.assignment_yettostart = assignments.yettostart || [];
          this.assignment_cancelled = assignments.cancelled || [];
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    "assignment_ongoing": function(){
      this.$nextTick(() => {
        if(this.assignment_ongoing.length > 0){
            // Ongoing rating chart
            // console.log("Existing", this.charts["ongoingrating"]);
            if(this.charts["ongoingrating"]) this.charts["ongoingrating"].destroy();
            this.charts["ongoingrating"] = new Chart(document.getElementById('ongoingrating'), this.chart_ongoing_rating_data);
          }
        });
    },
    "arealist_ongoing": function(){
      this.$nextTick(() => {
        if(this.arealist_ongoing.length > 0){
            // Ongoing area chart
            let cd = [];
            let cl = [];
            let clr = [];
            let chartdata = {...this.area_chart_data};

          for (const i of this.arealist_ongoing) {
             cd.push(1);
              cl.push(this.arealist[i]);
              clr.push(this.colors[i]);
          }
            chartdata.data.labels = cl;
            chartdata.data.datasets[0].data = cd;
            chartdata.data.datasets[0].backgroundColor = clr;
            if(this.charts["ongoingarea"]) this.charts["ongoingarea"].destroy();
            this.charts["ongoingarea"] = new Chart(document.getElementById('ongoingarea').getContext("2d"), chartdata);    
          }
        });
    },
    "$store.state.client": function(){
      this.refreshData();
    }
  }
}
</script>

<style scoped>
/* .subs-icon{
  background-color: var(--v-primary-base) !important;
  color: white;
} */
</style>
